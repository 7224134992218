<template>
  <b-card no-body>
    <!-- {{ org_id }} -->
    <!-- {{ stats }} -->
    <!-- {{ internalrisk }} -->
    <!-- title and dropdown -->
    <b-card-header class="pb-0">
      <b-card-title>Internal</b-card-title>
      <!-- <b-dropdown
        no-caret
        right
        text="Last 7 Days"
        variant="transparent"
        class="chart-dropdown"
        toggle-class="p-50"
        size="sm"
      >
        <b-dropdown-item
          v-for="day in trackerData.lastDays"
          :key="day"
        >
          {{ day }}
        </b-dropdown-item>
      </b-dropdown> -->
    </b-card-header>
    <!--/ title and dropdown -->

    <b-card-body>
      <b-row>
        <b-col
          sm="2"
          class="d-flex flex-column flex-wrap text-center cursor-pointer"
          @click="handleDashboardItemClick('assets/?grouptype=internal')"
        >
          <h1 class="font-large-2 font-weight-bolder mt-2 mb-0">
            {{ internalrisk.total_assets }}
          </h1>
          <b-card-text>Total Assets</b-card-text>
        </b-col>

        <!-- chart -->
        <b-col sm="10" class="d-flex justify-content-center mb-2">
          <!-- apex chart -->
          <vue-apex-charts
            type="radialBar"
            height="270"
            :options="supportTrackerRadialBar.chartOptions"
            :series="series"
          />
        </b-col>
        <!--/ chart -->
      </b-row>

      <!-- chart info -->
      <div class="d-flex justify-content-between">
        <div
          class="text-center mr-1 cursor-pointer"
          @click="
            handleDashboardItemClick('assets/?risk_level=5&grouptype=internal')
          "
        >
          <b-card-text class="mb-50"> Critical </b-card-text>
          <span class="font-large-1 font-weight-bold">{{
            internalrisk.risk_profile.critical_risk_assets
          }}</span>
        </div>
        <div
          class="text-center mr-1 cursor-pointer"
          @click="
            handleDashboardItemClick('assets/?risk_level=4&grouptype=internal')
          "
        >
          <b-card-text class="mb-50"> High</b-card-text>
          <span class="font-large-1 font-weight-bold">{{
            internalrisk.risk_profile.high_risk_assets
          }}</span>
        </div>
        <div
          class="text-center mr-1 cursor-pointer"
          @click="
            handleDashboardItemClick('assets/?risk_level=3&grouptype=internal')
          "
        >
          <b-card-text class="mb-50"> Medium </b-card-text>
          <span class="font-large-1 font-weight-bold">{{
            internalrisk.risk_profile.medium_risk_assets
          }}</span>
        </div>
        <div
          class="text-center mr-1 cursor-pointer"
          @click="
            handleDashboardItemClick(
              'assets/?risk_level=0&risk_level=1&risk_level=2&grouptype=internal'
            )
          "
        >
          <b-card-text class="mb-50"> Low </b-card-text>
          <span class="font-large-1 font-weight-bold">{{
            internalrisk.risk_profile.low_risk_assets
          }}</span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BRow,
  BCol,
  BCardText,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardBody,
    BRow,
    BCol,
  },
  props: {
    internalrisk: {
      type: Object,
      required: true,
    },
    org_id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      //   series:[100],
      supportTrackerRadialBar: {
        series: [50],
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: "65%",
              },
              track: {
                background: "#fff",
                strokeWidth: "100%",
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: "#5e5873",
                  fontSize: "1rem",
                },
                value: {
                  offsetY: 15,
                  color: "#5e5873",
                  fontSize: "1.714rem",
                },
              },
            },
          },
          colors: [$themeColors.danger],
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: ["Risky Assets"],
        },
      },
    };
  },

  computed: {
    // a computed getter
    series: function () {
      // var c = Math.round(
      //   ((
      //     this.internalrisk.risk_profile.critical_risk_assets +
      //     this.internalrisk.risk_profile.high_risk_assets +
      //     this.internalrisk.risk_profile.medium_risk_assets  ) /
      //     this.internalrisk.total_assets) *
      //     100
      // );
      // console.log("c", c);
      // return [c];

      const totalAssets = this.internalrisk.total_assets;
      let c = 0;
      if (totalAssets !== 0) {
        c = Math.round(
          ((this.internalrisk.risk_profile.critical_risk_assets +
            this.internalrisk.risk_profile.high_risk_assets) /
            totalAssets) *
            100
        );
      }

      console.log("c", c);
      return [c];
    },
  },
  methods: {
    handleDashboardItemClick(route) {
      this.$cookies.set(
        "redirectTMPath",
        route,
        null,
        null,
        process.env.VUE_APP_DOMAIN
      );
      const url = `${process.env.VUE_APP_TM_WEB_URL}${route}`;
      window.open(url, "_blank");
    },
  },
};
</script>
