<template>
  <b-card-code title="Top Breached Emails">
    <!-- {{ items }} -->
    <b-table responsive id="blTable" :fields="fields" :items="items">
      <template #cell(is_addressed)="data">
        <b-badge variant="success" v-if="data.item.is_addressed == true"
          >Yes</b-badge
        >
        <b-badge variant="danger" v-if="data.item.is_addressed == false"
          >No</b-badge
        >
      </template>
      <template #cell(is_breached)="data">
        <b-badge variant="success" v-if="data.item.is_breached == true"
          >Yes</b-badge
        >
        <b-badge variant="danger" v-if="data.item.is_breached == false"
          >No</b-badge
        >
      </template>
    </b-table>
    <!-- <div class="d-flex justify-content-end py-1" v-if="items.length > 9">
      <router-link class="text-white" :to="'/dashboard/domain-list'">
        <button
          type="button"
          class="btn btn-primary btn-sm mr-1"
          style="position: relative"
        >
          See More >>
        </button>
      </router-link>
    </div> -->
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable, BBadge } from "bootstrap-vue";
export default {
  components: {
    BCardCode,
    BTable,
    BBadge,
  },
  props: {
    // org_id: {
    //   type: Number,
    //   required: true,
    // },
  },
  data() {
    return {
      fields: [
        { key: "email", label: "Email" },
        { key: "domain", label: "Domain" },
        { key: "is_breached", label: "Breached" },
        { key: "is_addressed", label: "Adressed" },
      ],
      items: [],
      organization_id:this.$store.state.app.tenantId
    };
  },
  created: function () {
    this.load();
  },
  methods: {
    load: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json",'org-id':this.organization_id },
        url:
          process.env.VUE_APP_TM_API_URL +
          "dashboard/v2/breached-emails",
      };
      this.$http(options).then((res) => {
        console.log(res.data.results);
        // this.toastMessage(res)  // method call to method2
        this.items = res.data.results;
      });
    },
  },
};
</script>