<template>
  <div
    style="width: 250px; height: 60px"
    class="main-parent cursor-pointer rounded border-light mx-75 my-75 shadow d-flex flex-row align-items-start justify-content-start py-75 px-50"
    @click="clickAction()"
  >
    <div class="h-100 w-auto d-flex align-items-start justify-content-center mr-75">
      <feather-icon :icon="icon" size="24" />
    </div>
    <div
      class="h-100 w-75 d-flex flex-column align-items-start justify-content-start"
    >
      <h5 class="mb-0 font-weight-bold text-primary text-truncate">
        {{ title }}
      </h5>
      <small class="mb-0 font-weight-bold mt-25 text-truncate">{{ description }}</small>
    </div>
  </div>
</template>

<script>
import FeatherIcon from "../@core/components/feather-icon/FeatherIcon.vue";
export default {
  components: { FeatherIcon },
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
      default: "",
    },
    clickAction:{
        type: Function,
        required: false,
        default:()=>{},
    }
  },
  methods:{
     
  },
};
</script>

<style lang="scss" scoped>
.main-parent {
  transition: all 0.3s ease-in-out;
  &:hover {
    scale: 1.05;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
}
</style>