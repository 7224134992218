<template>
  <b-card-code title="New Domain Discovered">
    <!-- {{ items }} -->
    <b-table responsive id="blTable" :fields="fields" :items="items">
      <template #cell(domain)="data">
        {{ data.item.domain }} <br />
        <!-- <button
          v-if="data.item.is_newly_founded"
          @click="addToAssets(data.item.id, 'domain')"
          type="button"
          class="btn btn-outline-warning btn-sm mr-1"
          style="position: relative"
        >
          Add to assets
        </button>
        <button
          v-else-if="!data.item.domain_added_to_asset"
          @click="addToAssets(data.item.id, 'domain')"
          type="button"
          class="btn btn-outline-primary btn-sm mr-1"
          style="position: relative"
        >
          Add to assets
        </button> -->
        <!-- <button
          v-else
          type="button"
          class="btn btn-outline-success btn-sm mr-1"
          style="position: relative"
        >
          Added
        </button> -->
      </template>
      <template #cell(ip_address)="data">
        {{ data.item.ip_address }} <br />
        <!-- <button
          @click="addToAssets(data.item.id, 'ip')"
          type="button"
          class="btn btn-outline-warning btn-sm mr-1"
          style="position: relative"
          v-if="data.item.is_newly_founded"
        >
          Add to assets
        </button>
        <button
          @click="addToAssets(data.item.id, 'ip')"
          type="button"
          class="btn btn-outline-primary btn-sm mr-1"
          style="position: relative"
          v-else-if="!data.item.ip_added_to_asset"
        >
          Add to assets
        </button>
        <button
          v-else
          type="button"
          class="btn btn-outline-success btn-sm mr-1"
          style="position: relative"
        >
          Added
        </button> -->
      </template>
    </b-table>
    <!-- <div class="d-flex justify-content-end py-1" v-if="items.length > 9">
      <router-link class="text-white" :to="'/dashboard/domain-list'">
        <button
          type="button"
          class="btn btn-primary btn-sm mr-1"
          style="position: relative"
        >
          See More >>
        </button>
      </router-link>
    </div> -->
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
export default {
  components: {
    BCardCode,
    BTable,
  },
  props: {
    // org_id: {
    //   type: Number,
    //   required: true,
    // },
  },
  data() {
    return {
      fields: [
        { key: "asset", label: "Name" },
        { key: "domain", label: "Domain" },
        { key: "ip_address", label: "IP" },
      ],
      items: [],
      organization_id:this.$store.state.app.tenantId,
      };
  },
  created: function () {
      this.load();
  },
  methods: {
    load: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json", 'org-id':this.organization_id },
        url:
          process.env.VUE_APP_TM_API_URL +
          "dashboard/v1/domains-discovered",
      };
      this.$http(options).then((res) => {
        console.log(res.data.results);
        // this.toastMessage(res)  // method call to method2
        this.items = res.data.results;
      });
    },
    addToAssets: function (id, type) {
      let data = {
        type: type,
      };
      // add-to-asset
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset-discovery/discovered-assets/" +
          id +
          "/add-to-asset/",
      };
      this.$http(options).then((res) => {
        this.load();
      });
    },
  },
};
</script>