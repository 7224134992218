<template>
  <div>
    <b-tabs>
      <!-- {{ this.$store.state.app.tenantId}} -->
      <b-tab title="Quick Actions" lazy>
        <quick-scans />
      </b-tab>
      <b-tab
        title="GRC Dashboard"
        v-if="this.$store.state.app.currentWorkspaceModules.includes('GRC')"
        lazy
      >
        <div class="row">
          <div class="col-12"></div>
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h4>Pending Tasks</h4>
                <b-button
                  class="btn btn-primary btn-sm float-right mb-1"
                  variant="primary"
                  @click="gotoGrcTasks"
                >
                  View All
                </b-button>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table">
                    <thead class="thead-light">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Task</th>
                        <th scope="col">Due Date</th>
                        <th scope="col">Priority</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(task, index) in tasks"
                        :key="task._id"
                        @click="gotoGrcTask(task._id)"
                        class="cursor-pointer"
                      >
                        <th scope="row" style="width: 60px">{{ index + 1 }}</th>
                        <td>
                          <p class="font-weight-bolder mb-0">
                            {{ task.title }}
                          </p>
                        </td>
                        <td>
                          <p class="font-weight-bold mb-0">
                            {{ task.due_date | moment }}
                          </p>
                        </td>
                        <td>
                          <b-badge variant="danger" v-if="task.priority == 3">
                            High
                          </b-badge>
                          <b-badge variant="warning" v-if="task.priority == 2">
                            Medium
                          </b-badge>
                          <b-badge variant="success" v-if="task.priority == 1">
                            Low
                          </b-badge>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h4>Ongoing Gap Assessments</h4>
                <button
                  class="btn btn-primary btn-sm float-right mb-1"
                  @click="gotogaps()"
                >
                  View All
                </button>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead class="thead-light">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col" class="text-center">Progress</th>
                        <th scope="col" class="text-center">Controls</th>
                        <th scope="col" class="text-center">Gap</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(gap, index) in this.gaps"
                        :key="gap._id"
                        @click="gotoGap(gap._id)"
                        class="cursor-pointer"
                      >
                        <th scope="row">{{ index + 1 }}</th>
                        <td style="width: 120px">
                          <p
                            class="mb-0 font-weight-bolder text-truncate"
                            style="width: 120px"
                            v-b-tooltip.hover.top.v-secondary
                            :title="gap.title"
                          >
                            {{ gap.title }}
                          </p>
                        </td>

                        <td>
                          <div
                            class="d-flex align-items-center justify-content-center"
                          >
                            <progress-badge
                              :value="gap.completion"
                              :colorsReversed="true"
                              :min="0"
                              :max="100"
                            />
                          </div>
                        </td>
                        <td>
                          <div
                            class="d-flex justify-content-center align-items-center"
                          >
                            <b-avatar
                              :text="gap.total_controls.toString()"
                              variant="light-primary"
                            />
                          </div>
                        </td>
                        <td>
                          <div
                            class="d-flex align-items-center justify-content-center"
                          >
                            <progress-badge
                              :value="gap.gap"
                              :min="0"
                              :max="100"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- <div class="card" >
            <div
              class="card-header d-flex flex-row align-items-center justify-content-between"
            >
              <h4 class="mb-0">Ongoing T.P.R Assessments</h4>
              <b-button
                variant="primary"
                size="sm"
                @click="handleVendorAssessmentViewAllClick()"
                >View All</b-button
              >
            </div>
            <div class="card-body">
              <table role="table" class="table table-hover">
                <thead role="rowgroup">
                  <tr role="row">
                    <th role="columnheader" scope="col">Name</th>
                    <th role="columnheader" scope="col">Description</th>
                    <th role="columnheader" scope="col" class="text-center">
                      Questionnaires
                    </th>

                    <th role="columnheader" scope="col" class="text-center">
                      Progress
                    </th>
                  </tr>
                </thead>
                <tbody role="rowgroup">
                  <tr
                    v-for="assessment in trpAssessments"
                    :key="assessment._id"
                    role="row"
                    class="cursor-pointer"
                    @click="handleAssessmentClick(assessment._id)"
                  >
                    <td role="cell" style="width: 120px">
                      <div
                        class="d-flex justify-content-start"
                        style="width: 120px"
                        v-b-tooltip.hover.top.v-secondary
                        :title="assessment.title"
                      >
                        <p class="font-weight-bolder text-truncate mb-0">
                          {{ assessment.title }}
                        </p>
                      </div>
                    </td>

                    <td role="cell" style="width: 120px">
                      <div
                        class="d-flex justify-content-start"
                        style="width: 120px"
                        v-b-tooltip.hover.top.v-secondary
                        :title="assessment.description"
                      >
                        <p class="font-weight-bold text-truncate mb-0">
                          {{ assessment.description }}
                        </p>
                      </div>
                    </td>

                    <td role="cell">
                      <div class="d-flex justify-content-center">
                        <b-avatar
                          :text="assessment.total_questionnaires.toString()"
                          variant="light-primary"
                        />
                      </div>
                    </td>

                    <td role="cell">
                      <div class="d-flex justify-content-center">
                       
                        <progress-badge
                          :colorsReversed="true"
                          :value="assessment.completion.toFixed(0)"
                          :min="0"
                          :max="100"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> -->
          </div>
          <!-- <div class="col-4">
            <div class="card">
              <div class="card-body">
                <h1 class="greet" style="color: #040f81">
                  <b>{{ greeting }} !</b>
                </h1>

                <h3 class="greet">
                  {{ $store.state.app.user.firstname }}
                  {{ $store.state.app.user.lastname }}
                </h3>

                <h1
                  class="mb-75 mt-2 pt-50"
                  v-if="greeting == 'Good Morning'"
                  style="font-size: 2.5rem; float: right"
                >
                  🌼
                </h1>
                <h1
                  class="mb-75 mt-2 pt-50"
                  v-if="greeting == 'Good Afternoon'"
                  style="font-size: 2.5rem; float: right"
                >
                  🌞
                </h1>
                <h1
                  class="mb-75 mt-2 pt-50"
                  v-if="greeting == 'Good Evening'"
                  style="font-size: 2.5rem; float: right"
                >
                  ☕️
                </h1>
              </div>
            </div>
          </div> -->
        </div>
      </b-tab>
      <b-tab
        title="Threat Management Dashboard"
        v-if="
          this.$store.state.app.currentWorkspaceModules.includes(
            'Threat Management'
          )
        "
        lazy
      >
        <!-- {{ currentWorkspace.modules }} -->
        <!-- {{ currentWorkspaceModule }} -->
        <!-- {{ this.$store.state.app.currentWorkspace }} -->
        <Tmdash></Tmdash>
      </b-tab>
    </b-tabs>
    <b-modal
      id="modal-1"
      title="Choose one.."
      ref="modal-1"
      hide-footer
      modal-class="modal-info"
      centered
      size="md"
    >
      <div class="d-flex align-items-center justify-content-center">
        <div>
          <b-button variant="primary" class="mr-1" @click="goToTMScans"
            >Initiate a Scan</b-button
          >
        </div>
        <div>
          <b-button variant="primary" @click="gotoGrcAssessments"
            >Initiate Gap Assessment</b-button
          >
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modal-select-org"
      ref="modal-select-org"
      hide-footer
      modal-class="modal-info"
      centered
      size="md"
      :no-close-on-backdrop="true"
      ok-only
    >
      <template #modal-header="{}">
        <h5 class="text-warning mb-0">
          <feather-icon icon="AlertTriangleIcon" class="mr-25" />Warning
        </h5>
      </template>
      <div class="d-flex flex-column align-items-center jusify-content-between">
        <b-form-group label="Choose Organization" class="w-100">
          <b-form-select
            name=""
            id=""
            class="form-control"
            @change="selectChoose(selectedWorkspace)"
            v-model="selectedWorkspace"
            placeholder="Choose Organization"
          >
            <option
              :value="value"
              v-for="(value, key) in this.$store.state.app.workspaces"
              :key="key"
            >
              {{ value.name }}
            </option>
          </b-form-select>
        </b-form-group>

        <h5 class="my-1">-- Or --</h5>
        <div
          class="d-flex flex-wrap flex-row align-items-center justify-content-center border rounded p-1 w-100"
        >
          <b-button
            size="sm"
            variant="primary"
            class="m-25"
            @click="
              () => {
                this.$store.dispatch('app/logout').then(() => {
                  const url = '/login';
                  // if (this.$store.state.app.user.is_staff) url = '/organizations'
                  this.$router.replace(url);
                  console.log('Logged Out');
                });
              }
            "
          >
            <feather-icon icon="LogOutIcon" class="mr-25" />
            Logout</b-button
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BModal,
  BImg,
  BButton,
  BAvatar,
  BTabs,
  BTab,
  BFormSelect,
  BFormGroup,
  BBadge,
} from "bootstrap-vue";
import moment from "moment";
import ProgressBadge from "../../components/ProgressBadge.vue";
import Tmdash from "./components/v1.vue";
import QuickScans from "./components/QuickScans.vue";
export default {
  data() {
    return {
      tasks: [],
      gaps: [],
      grcUrl: process.env.VUE_APP_GRC_URL,
      currentWorkspace: [],
      currentWorkspaceModule: [],
      selectedWorkspace: [],
      is_currentmodule: this.$store.state.app.currentWorkspace,
    };
  },
  components: {
    BCard,
    BCardText,
    BLink,
    BModal,
    BImg,
    BButton,
    moment,
    ProgressBadge,
    BAvatar,
    BTabs,
    BTab,
    Tmdash,
    BFormSelect,
    BFormGroup,
    BBadge,
    QuickScans,
  },
  computed: {
    currentWorkspaceModules() {
      return this.$store.state.app.currentWorkspaceModules;
    },
    VUE_APP_GRC_WEB_URL() {
      return process.env.VUE_APP_GRC_WEB_URL;
    },
  },
  watch: {
    is_currentmodule() {
      this.loadCurrentModules();
    },
  },
  mounted() {
    if (this.$store.state.app.currentWorkspace.length == 0) {
      this.loadOrgSelectModal();
    }
    this.loadCurrentModules();
    if (
      !this.$store.state.app.currentWorkspace ||
      typeof this.$store.state.app.currentWorkspace == "undefined" ||
      this.$store.state.app.currentWorkspace === null
    ) {
      if (
        this.$store.state.app.workspaces &&
        this.$store.state.app.workspaces.length == 1
      ) {
        this.selectChoose(this.$store.state.app.workspaces[0]);
      } else {
        this.loadOrgSelectModal();
      }
    } else {
      this.loadCurrentModules();
    }
  },
  filters: {
    moment: function (date) {
      return moment(date).format("Do MMMM YYYY");
    },
  },
  computed: {
    greeting() {
      var myDate = new Date();
      var hrs = myDate.getHours();

      var greet;

      if (hrs < 12) greet = "Good Morning";
      else if (hrs >= 12 && hrs <= 17) greet = "Good Afternoon";
      else if (hrs >= 17 && hrs <= 24) greet = "Good Evening";
      return greet;
    },
  },
  methods: {
    handleItemClick(envWebUrl, route, service = "GRC") {
      if (service == "TM") {
        this.$cookies.set(
          "redirectTMPath",
          route,
          null,
          null,
          process.env.VUE_APP_DOMAIN
        );
      }
      if (service == "GRC") {
        this.$cookies.set(
          "redirectGRCPath",
          route,
          null,
          null,
          process.env.VUE_APP_DOMAIN
        );
      }
      const url = `${envWebUrl}${route}`;
      window.open(url, "_blank");
    },

    goToTMScans() {
      this.handleItemClick(process.env.VUE_APP_TM_WEB_URL, `assets/add`, "TM");
    },

    gotoGrcAssessments() {
      this.handleItemClick(
        process.env.VUE_APP_GRC_WEB_URL,
        `gapassessment`,
        "GRC"
      );
    },

    // handleItemClick(envWebUrl, route) {
    //   this.$cookies.set(
    //     "redirectGRCPath",
    //     `/${route}`,
    //     null,
    //     null,
    //     process.env.VUE_APP_DOMAIN
    //   );
    //   const url = `${envWebUrl}${route}`;
    //   window.open(url, "_blank");
    // },

    gotoGrcTask(id) {
      this.handleItemClick(
        process.env.VUE_APP_GRC_WEB_URL,
        `tasks?task_id=${id}`
      );
    },

    gotoGrcTasks() {
      // const url = process.env.VUE_APP_GRC_WEB_URL;
      // window.open(url, "GRC");
      this.handleItemClick(process.env.VUE_APP_GRC_WEB_URL, "tasks");
    },
    gotoGap(id) {
      this.handleItemClick(
        process.env.VUE_APP_GRC_WEB_URL,
        `gapassessment/${id}/controlgapassessment`
      );
    },
    gotogaps() {
      // const url = process.env.VUE_APP_GRC_WEB_URL;
      // window.open(url, "GRC");
      this.handleItemClick(process.env.VUE_APP_GRC_WEB_URL, "gapassessment");
    },
    selectChoose(payload) {
      // debugger
      this.$store.dispatch("app/changetenant", payload).then(
        (response) => {
          this.loadCurrentModules();
          window.location.reload();

          // this.selectedWorkspace = [];
        },
        (error) => {
          console.log("Something went wrong");
        }
      );
    },
    loadModal() {
      this.$refs["modal-1"].show();
    },
    loadOrgSelectModal() {
      this.$refs["modal-select-org"].show();
    },
    loadCurrentModules() {
      if (this.$store.state.app.currentWorkspaceModules.includes("GRC")) {
        this.getTasks();
        this.getGaps();
      }
    },
    getTasks() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_CISO_API_URL}/tasks?page=1&limit=5`,
        params: {
          status: 1,
        },
      };
      this.$http(options)
        .then((resp) => {
          this.tasks = resp.data.data.data;
        })
        .catch((err) => {
          console.log(err);
          // this.handleError(err);
        });
    },
    getGaps(uid) {
      console.log("Get Gaps", "1675938132510");
      console.log(this.$store.state.app.user.user_id + "inside function");
      const options = {
        method: "GET",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_CISO_API_URL}/gap-assessments`,
        params: {
          limit: 5,
          // assessed_by: this.$store.state.app.user.user_id,
        },
      };
      this.$http(options)
        .then((resp) => {
          this.gaps = resp.data.data.data;
          if (
            this.gaps.length == 0 &&
            this.$store.state.app.currentWorkspace.is_public_signup == true
          ) {
            this.loadModal();
          }
        })
        .catch((err) => {
          console.log(err);
          // this.handleError(err);
        });
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Caveat&display=swap");
.greet {
  font-family: "Caveat", cursive;
}
</style>
