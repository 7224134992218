<template>
  <div class="card p-2">
    <div
      class="w-100 d-flex flex-row align-items-center justify-content-between"
    >
      <div
        class="d-flex align-items-center justify-content-center h-100"
        style="max-width: 30%; min-width: 346px"
      >
        <b-img
          :src="require('@/assets/images/pages/register-v2.svg')"
          fluid-grow
          alt="Quick Actions"
        />
      </div>
      <div
        class="h-100 d-flex flex-column align-items-start justify-content-start pl-4"
        style="width: 69%"
      >
        <h3 class="mb-1 font-weight-bold">Seamlessly Access Our Services</h3>
        <div
          class="d-flex flex-column align-items-start justify-content-start w-100"
        >
          <div
            class="w-100 h-100 d-flex flex-row align-items-start justify-content-start flex-wrap"
          >
            <template v-for="(item, i) in actions">
              <scan-item-card
                :key="i"
                :icon="item.icon"
                :title="item.title"
                :description="item.description"
                :clickAction="item.clickAction"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard, BCardText, BCardBody, BButton, BImg } from "bootstrap-vue";
import BCardActionsVue from "../../../@core/components/b-card-actions/BCardActions.vue";
import ScanItemCard from "../../../components/ScanItemCard.vue";
export default {
  components: {
    BCardActionsVue,
    BCard,
    BCardText,
    BCardBody,
    BButton,
    BImg,
    ScanItemCard,
  },
  computed: {
    actions() {
      return [
        {
          title: "Domain Discovery",
          description: "Discover your domains",
          icon: "DatabaseIcon",
          clickAction: () => {
            this.handleItemClick(
              process.env.VUE_APP_TM_WEB_URL,
              "Discovery?tabIndex=1"
            );
          },
        },
        {
          title: "Email Breach",
          description: "Check email breach",
          icon: "MailIcon",
          clickAction: () => {
            this.handleItemClick(
              process.env.VUE_APP_TM_WEB_URL,
              "Discovery?tabIndex=2"
            );
          },
        },
        {
          title: "Asset Discovery",
          description: "Discover your assets",
          icon: "GridIcon",
          clickAction: () => {
            this.handleItemClick(
              process.env.VUE_APP_TM_WEB_URL,
              "Discovery?tabIndex=0"
            );
          },
        },
        {
          title: "Web Application Scan",
          description: "Scan your web application",
          icon: "GlobeIcon",
          clickAction: () => {
            this.handleItemClick(
              process.env.VUE_APP_TM_WEB_URL,
              "?quickAction=web"
            );
          },
        },
        {
          title: "Network Scan",
          description: "Scan your networks",
          icon: "ApertureIcon",
          clickAction: () => {
            this.handleItemClick(
              process.env.VUE_APP_TM_WEB_URL,
              "?quickAction=network"
            );
          },
        },
        {
          title: "Check SSL Score",
          description: "Check SSL Score",
          icon: "ShieldIcon",
          clickAction: () => {
            this.handleItemClick(
              process.env.VUE_APP_TM_WEB_URL,
              "?quickAction=ssl"
            );
          },
        },
        {
          title: "IP Reputation",
          description: "IP Reputation scan",
          icon: "MapPinIcon",
          clickAction: () => {
            this.handleItemClick(
              process.env.VUE_APP_TM_WEB_URL,
              "?quickAction=ipreputation"
            );
          },
        },
        {
          title: "Initiate a gap assessment",
          description: "Initiate gap assessment",
          icon: "ChevronsUpIcon",
          clickAction: () => {
            this.handleItemClick(
              process.env.VUE_APP_GRC_WEB_URL,
              "gapassessment",
              "GRC"
            );
          },
        },
        {
          title: "Explore our frameworks",
          description: "Explore our frameworks",
          icon: "SearchIcon",
          clickAction: () => {
            this.handleItemClick(
              process.env.VUE_APP_GRC_WEB_URL,
              "standards",
              "GRC"
            );
          },
        },
      ];
    },
  },
  methods: {
    handleItemClick(envWebUrl, route, service = "TM") {
      if (service == "TM") {
        this.$cookies.set(
          "redirectTMPath",
          route,
          null,
          null,
          process.env.VUE_APP_DOMAIN
        );
      }
      if (service == "GRC") {
        this.$cookies.set(
          "redirectGRCPath",
          route,
          null,
          null,
          process.env.VUE_APP_DOMAIN
        );
      }

      // const url = `${envWebUrl}app-switch/`;
      const url = `${envWebUrl}${route}`;
      window.open(url, "_blank");
    },
  },
};
</script>

<style>
</style>