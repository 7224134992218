<template>
  <div>
    <b-modal
      v-if="$store.state.app.user.is_staff && $store.state.app.org_id == null"
      ref="my-modal"
      hide-footer
      modal-class="modal-warning"
      :no-close-on-backdrop="true"
      ok-only
      ok-variant="secondary"
    >
      <template #modal-header="{}">
        <h5 class="text-warning mb-0">
          <feather-icon icon="AlertTriangleIcon" class="mr-25" />Warning
        </h5>
      </template>
      <div class="d-flex flex-column align-items-center justify-content-start">
        <h5>Please choose an organization to access the selected page.</h5>
        <v-select
          v-model="root_org_id"
          label="text"
          :options="organizations"
          autocomplete
          style="width: 400px"
          :reduce="(org_id) => org_id.value"
          class="mt-50"
        />
        <h5 class="my-1">-- Or Navigate To --</h5>
        <div
          class="d-flex flex-wrap flex-row align-items-center justify-content-center border rounded p-1 w-100"
        >
          <b-button
            @click="$router.push({ name: 'Logs' })"
            size="sm"
            variant="primary"
            class="m-25"
          >
            <feather-icon icon="FileIcon" class="mr-25" />
            Logs</b-button
          >
          <b-button
            @click="$router.push('/organizations')"
            size="sm"
            variant="primary"
            class="m-25"
          >
            <feather-icon icon="FolderIcon" class="mr-25" />
            Organizations</b-button
          >
          <b-button
            @click="$router.push('/scanners')"
            size="sm"
            variant="primary"
            class="m-25"
          >
            <feather-icon icon="BoxIcon" class="mr-25" />
            Scanners</b-button
          >
          <b-button
            @click="$router.push('/roles')"
            size="sm"
            variant="primary"
            class="m-25"
          >
            <feather-icon icon="AwardIcon" class="mr-25" />
            Roles</b-button
          >
          <b-button
            @click="$router.push('/users')"
            size="sm"
            variant="primary"
            class="m-25"
          >
            <feather-icon icon="UsersIcon" class="mr-25" />
            Users</b-button
          >

          <b-button
            @click="$router.push('/Agent')"
            size="sm"
            variant="primary"
            class="m-25"
          >
            <feather-icon icon="TriangleIcon" class="mr-25" />
            Agent</b-button
          >
        </div>
      </div>
    </b-modal>
    <!-- {{ currentWorkspaceModule }} -->
    <!-- <b-tabs>
      <b-tab title="General"> -->
    <!-- {{generalCount}} -->
    <!-- {{ organization_id }} -->
    <GeneralCount :generalCount="generalCount" />
    <b-row class="match-height">
      <div class="w-100 mx-2"><h4>Risk Posture</h4></div>
      <b-col lg="4">
        <b-card>
          <div
            class="d-flex justify-content-center mb-1"
            v-if="main_data_loading"
          >
            <b-spinner class="float-right" label="Floated Right" />
          </div>

          <OverallRisk :overallrisk="overallrisk" v-if="!main_data_loading" />
        </b-card>
      </b-col>
      <b-col lg="4">
        <b-card>
          <div
            class="d-flex justify-content-center mb-1"
            v-if="main_data_loading"
          >
            <b-spinner class="float-right" label="Floated Right" />
          </div>
          <InternetTracker
            :internetrisk="internetrisk"
            :org_id="org_id"
            v-if="!main_data_loading"
          />
        </b-card>
      </b-col>
      <b-col lg="4">
        <b-card>
          <div
            class="d-flex justify-content-center mb-1"
            v-if="main_data_loading"
          >
            <b-spinner class="float-right" label="Floated Right" />
          </div>
          <InternalFacing
            :internalrisk="internalrisk"
            :org_id="org_id"
            v-if="!main_data_loading"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col lg="6">
        <TaskOverView :stats="stats" :tasks_completion="tasks_completion" />
      </b-col>
      <b-col lg="6">
        <VulnOverview :values="vuln_values" :org_id="org_id" />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <!-- <b-col lg="6">
        <IPscore :org_id="org_id" />
      </b-col> -->
      <b-col lg="12">
        <DomainDiscovered />
      </b-col>
      <b-col lg="6">
        <BreachedEmailsChart :values="breached_values" />
      </b-col>
    </b-row>
    <!-- </b-tab> -->
    <!-- <b-tab title="Vulnerabilties" id="tab1" @click="handleTabClick('tab1')">
        <VulnCountList :org_id="org_id" :vulnCount="vulnCount" />
        <div class="d-flex justify-content-end my-1">
          <v-select
            v-model="group_filter_dash"
            label="text"
            taggable
            class="ml-1"
            placeholder="--Select Asset Group--"
            @input="vulnGroupSearch()"
            :options="group_filter_options"
            autocomplete
            style="width: 25%"
            :reduce="(group_filter) => group_filter.value"
          />
        </div>
        <VulnCountList :vulnCount="vulnDashboard" />
        <b-row class="match-height" id="section1">
          <b-col lg="6">
            <VulnData :vulnData="vulnDashboard" />
          </b-col>
          <b-col lg="6">
            <VulnByAge :vuln_by_age="vuln_by_age" />
            
          </b-col>
        </b-row>
        <b-row class="match-height" id="section1">
          <b-col lg="6">
            
            <MostCommonVulnerabilities :mostCommonVuln="mostCommonVuln" />
          </b-col>
          <b-col lg="6">
           
            <TopSevereVuln :severeVuln="severeVuln" />
          </b-col>
        </b-row>
        <b-row class="match-height" id="section1">
          <b-col lg="6">
            <VulnCVSSGraph :values="cvss_values" />
          </b-col>
        </b-row>
      </b-tab> -->
    <!-- <b-tab title="Assets" id="tab2" @click="handleTabClick('tab2')">
        <div class="d-flex justify-content-end my-1">
          <v-select
            v-model="group_filter_asset"
            label="text"
            taggable
            class="ml-1"
            placeholder="--Select Asset Group--"
            @input="assetGroupSearch()"
            :options="group_filter_options"
            autocomplete
            style="width: 25%"
            :reduce="(group_filter_asset) => group_filter_asset.value"
          />
        </div>
        <AssetOverview :assetCount="assetCount" />
        <b-row class="match-height" id="section1">
          <b-col lg="6">
            <AssetsMostAffected :assets_most_affected="assets_most_affected" />
          </b-col>
          <b-col lg="6">
            <AssetsMostExploited
              :assets_most_exploittable="assets_most_exploittable"
            />
          </b-col>
        </b-row>
        <b-row class="match-height">
          <b-col lg="12">
            <b-card>
              <TopSeveVuln
                :top_severe_vulnerabilities="top_severe_vulnerabilities"
              />
            </b-card>
          </b-col>
        </b-row>
        <b-row class="match-height">
          <b-col lg="12">
            <Baseline :org_id="org_id" :group_filter_asset="group_filter_asset" :tab_id="tab_id"/>
          </b-col>
        </b-row>
      </b-tab> -->
    <!-- <b-tab title="Scans" id="tab3" @click="handleTabClick('tab3')">
        <div class="d-flex justify-content-end my-1">
          <v-select
            v-model="group_filter_scan"
            label="text"
            taggable
            class="ml-1"
            placeholder="--Select Asset Group--"
            :options="group_filter_options"
            @input="scanGroupSearch()"
            autocomplete
            style="width: 25%"
            :reduce="(group_filter_scan) => group_filter_scan.value"
          />
        </div>
        <ScanOverview :scanCount="scanCount" />
        <b-row class="match-height">
          <b-col lg="6">
            <b-card>
              <UpcomingScans :upcomingScans="upcomingScans" />
            </b-card>
          </b-col>
          <b-col lg="6">
            <b-card>
              <RecentScans :recent_scans="recent_scans" />
            </b-card>
          </b-col>
          <b-col lg="6">
            <b-card>
              <TrendScan :scanTrend="scanTrend" />
            </b-card>
          </b-col>
          <b-col lg="6">
            <b-card>
              <ScanCredit :scanCreditTrend="scanCreditTrend" />
            </b-card>
          </b-col>
        </b-row>
       
      </b-tab> -->
    <!-- </b-tabs> -->
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BSpinner,
  BCard,
  BFormGroup,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BModal,
  BTabs,
  BTab,
  BAvatar,
} from "bootstrap-vue";
import vSelect from "vue-select";
// first row

import OverallRisk from "./overall_risk_tracker.vue";
import InternalFacing from "./internal_facing_tracker.vue";
import InternetTracker from "./internet_tracker.vue";
//import OrganizationRiskTracker from "./OrganizationRiskTracker.vue";

// second row bar and line graph

import GeneralCount from "./general_count.vue";
//import OverallVuln from "./overall_vuln_data.vue";
//import VulnData from "./vuln_data_linegraph.vue";
import BreachedEmails from "./breached_emails.vue";
import BreachedEmailsChart from "./breachedEmailChart.vue";
// vuln section
// import VulnCountList from "./vuln_count.vue";
// import MostCommonVulnerabilities from "./most_common_vuln.vue";
// import TopSevereVuln from "./top_severe_vuln.vue";
// import VulnCVSSGraph from "./vuln_cvss_graph.vue";
// import VulnByAge from "./vuln_by_age.vue";

// asset section
// import AssetOverview from "./assetOverview.vue";
// import TopSeveVuln from "./top-severe-asset-vuln.vue";

// scan section
// import ScanOverview from "./scan_overview.vue";
// import UpcomingScans from "./upcomingScan.vue";
// import RecentScans from "./recent_scans.vue";
// import TrendScan from "./trendScan.vue";
// import ScanCredit from "./scanCreditTrend.vue";

import TaskOverView from "./TaskOverview.vue";
import VulnOverview from "./VulnOverview.vue";
// import welcome from "./welcome.vue";
// import Actions from "./actions.vue";
// import GroupData from "./group_data.vue";

// import AssetsMostAffected from "./most_affected_assets.vue";
// import AssetsMostExploited from "./most_exploitable_assets.vue";

// import Baseline from "./baseline.vue";
// import Sslscore from "./sslscore.vue";
// import EmailSecurity from "./email_security.vue";
// import IPscore from "./ip_score.vue";
import DomainDiscovered from "./domains_discovered.vue";
// import InsecurePort from "./insecure_port.vue";
//import FeatherIcon from "../../@core/components/feather-icon/FeatherIcon.vue";
export default {
  components: {
    vSelect,
    BCard,
    BRow,
    BCol,
    BButton,
    BTabs,
    BTab,
    // welcome,
    //OrganizationRiskTracker,
    TaskOverView,
    VulnOverview,
    // Actions,
    // GroupData,
    BSpinner,
    // MostCommonVulnerabilities,
    // AssetsMostAffected,
    // AssetsMostExploited,
    BFormGroup,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BAvatar,

    // Baseline,
    // Sslscore,
    // EmailSecurity,
    // IPscore,
    DomainDiscovered,
    // InsecurePort,
    BModal,
    //FeatherIcon,

    GeneralCount,
    OverallRisk,
    InternalFacing,
    InternetTracker,

    //OverallVuln,
    // VulnData,
    BreachedEmailsChart,
    BreachedEmails,
    // VulnCountList,
    // TopSevereVuln,
    // VulnCVSSGraph,
    // AssetOverview,
    // TopSeveVuln,
    // VulnByAge,

    // ScanOverview,
    // UpcomingScans,
    // RecentScans,
    // TrendScan,
    // ScanCredit,
  },
  data() {
    return {
      // org_id: 0,
      // org_id: !this.$store.state.app.user.is_staff
      //   ? this.$store.state.app.user.profile.organization
      //   : this.$store.state.app.org_id,
      // org_id:this.$store.state.app.org_id,
      stats: {},
      tasks_completion: [],
      vuln_values: [],
      group_data: {},
      main_data_loading: true,
      group_data_loading: true,
      group_filter: [],
      group_filter_options: [],
      group_filter_dash: null,
      group_filter_asset: null,
      group_filter_scan: null,
      organizations: [{ value: null, text: "--Select Organization--" }],
      organization: "",
      getorg: "",
      overallrisk: {},
      overall_vuln_values: [],
      internalrisk: {},
      internal_vuln_values: [],
      internetrisk: {},
      internet_vuln_values: [],

      overallVuln: {},
      vulnData: {},
      generalCount: {},
      vulnCount: {},

      cvss_values: [],
      vuln_cvss: {},
      assetCount: {},
      scanCount: {},
      upcomingScans: [],
      recent_scans: [],
      scanTrend: {},
      scanCreditTrend: {},

      assets_most_affected: [],
      assets_most_exploittable: [],
      top_severe_vulnerabilities: [],
      vuln_by_age: {},
      vulnDashboard: {},
      mostCommonVuln: [],
      severeVuln: [],
      tab_id: "",
      currentWorkspaceModule: [],
      organization_id: this.$store.state.app.tenantId,
      breached_values: [],
      breached_data: {},
    };
  },
  computed: {
    org_id() {
      if (
        !this.$store.state.app.user.is_staff &&
        typeof this.$store.state.app.user.profile != "undefined" &&
        this.$store.state.app.user.profile != null
      ) {
        return this.$store.state.app.user.profile.organization;
      }
      return this.$store.state.app.org_id;
    },
    root_org_id: {
      get() {
        return this.$store.state.app.org_id;
      },
      set(value) {
        this.$store.dispatch("app/getOrg", value);
      },
    },
  },
  props: {
    current_workspace: {
      type: Array,
    },
  },

  mounted() {
    // this.current_workspace.map((mod)=>{
    //   this.currentWorkspaceModule.push(mod.name)
    // })
    console.log("TM org-id", this.$store.state.app.tenantId);
    if (
      this.$store.state.app.currentWorkspaceModules.includes(
        "Threat Management"
      )
    )
      this.load();
  },
  // async mounted() {
  //   if (this.$store.state.app.user.is_staff) {
  //     this.getorg = await this.getOrganisation();
  //     this.load();
  //   } else {
  //     this.load();
  //   }
  // },
  methods: {
    // handleTabClick(tabId) {
    //   this.tab_id = tabId;
    //   if (tabId === "tab1") {
    //     // execute function for tab 1
    //     this.loadVulnDashboard();
    //   } else if (tabId === "tab2") {
    //     this.loadAssetDashboard();
    //   } else if (tabId === "tab3") {
    //     this.loadScanDashboard();
    //   }
    // },
    checkIfOrganizationSelected() {
      if (
        this.$store.state.app.user.is_staff &&
        this.$store.state.app.org_id == null
      ) {
        this.getOrganisation();
        this.showModal();
      }
    },

    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },

    scrollToSection: function (event) {
      var sectionID = event.target.hash;
      var section = document.querySelector(sectionID);
      section.scrollIntoView({ behavior: "smooth" });
    },
    getOrganisation() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_CISO_API_URL +
          "organization/organization/get-all-org/",
      };
      var self = this;
      return this.$http(options).then((res) => {
        res.data.map(function (value, key) {
          let org = {
            value: res.data[key].org_id,
            text: res.data[key].org_name,
          };
          self.org_id = res.data[0].org_id;
          self.organizations.push(org);
        });
      });
    },
    scanGroupSearch: function () {
      // asset new api
      let url =
        process.env.VUE_APP_CISO_API_URL +
        "dashboard/v2/scan-dashboard?org_id=" +
        this.org_id;

      if (this.group_filter_scan != null) {
        url = url + "&group_id=" + this.group_filter_scan;
      }
      const options_ac = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      var self = this;
      this.$http(options_ac).then((res) => {
        self.scanCount = res.data.scan_counts;
        self.upcomingScans = res.data.scan_counts.upcoming_scans;
        self.recent_scans = res.data.recent_scans;
        self.scanTrend = res.data.recent_scan_trend.scan_trend;
        self.scanCreditTrend = res.data.recent_scan_trend.scan_credit_trend;
      });
    },
    assetGroupSearch: function () {
      // asset new api
      let url =
        process.env.VUE_APP_CISO_API_URL +
        "dashboard/v2/asset-dashboard?org_id=" +
        this.org_id;

      if (this.group_filter_asset != null) {
        url = url + "&group_id=" + this.group_filter_asset;
      }
      const options_ac = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      var self = this;
      this.$http(options_ac).then((res) => {
        self.assetCount = res.data.asset_count_data;
        self.assets_most_affected = res.data.assets_most_affected;
        self.assets_most_exploittable = res.data.assets_most_exploittable;
        self.top_severe_vulnerabilities = res.data.top_severe_vulnerabilities;
      });
    },
    vulnGroupSearch: function () {
      let url =
        process.env.VUE_APP_CISO_API_URL +
        "dashboard/v2/vulns-dashboard?org_id=" +
        this.org_id;

      if (this.group_filter_dash != null) {
        url = url + "&group_id=" + this.group_filter_dash;
      }
      const options_vuln_new = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      var self = this;
      this.$http(options_vuln_new).then((res) => {
        self.vulnDashboard = res.data;
        self.vuln_by_age = res.data.vulnerability_chart.vuln_by_age;
        self.mostCommonVuln = res.data.most_common_vulnerabilities;
        self.severeVuln = res.data.most_severe_vulnerabilities;
        // self.severeVuln = res.data.most_severe_vulnerabilities;
        self.vuln_cvss = res.data.cvss_score_based_vulns;
        this.cvss_values = [
          self.vuln_cvss.low_cvss_score,
          self.vuln_cvss.medium_cvss_score,
          self.vuln_cvss.high_cvss_score,
          self.vuln_cvss.critical_cvss_score,
        ];
      });
    },
    loadVulnDashboard: function () {
      // vuln new api
      const options_vuln_new = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_CISO_API_URL +
          "dashboard/v2/vulns-dashboard?org_id=" +
          this.org_id,
      };
      var self = this;
      this.$http(options_vuln_new).then((res) => {
        self.vulnDashboard = res.data;
        self.vuln_by_age = res.data.vulnerability_chart.vuln_by_age;
        self.mostCommonVuln = res.data.most_common_vulnerabilities;
        self.severeVuln = res.data.most_severe_vulnerabilities;
        // self.severeVuln = res.data.most_severe_vulnerabilities;
        self.vuln_cvss = res.data.cvss_score_based_vulns;
        this.cvss_values = [
          self.vuln_cvss.low_cvss_score,
          self.vuln_cvss.medium_cvss_score,
          self.vuln_cvss.high_cvss_score,
          self.vuln_cvss.critical_cvss_score,
        ];
      });
    },
    loadAssetDashboard: function () {
      // asset new api
      const options_ac = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_CISO_API_URL +
          "dashboard/v2/asset-dashboard?org_id=" +
          this.org_id,
      };
      var self = this;
      this.$http(options_ac).then((res) => {
        self.assetCount = res.data.asset_count_data;
        self.assets_most_affected = res.data.assets_most_affected;
        self.assets_most_exploittable = res.data.assets_most_exploittable;
        self.top_severe_vulnerabilities = res.data.top_severe_vulnerabilities;
      });
    },
    loadScanDashboard: function () {
      // asset new api
      const options_ac = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_CISO_API_URL +
          "dashboard/v2/scan-dashboard?org_id=" +
          this.org_id,
      };
      var self = this;
      this.$http(options_ac).then((res) => {
        self.scanCount = res.data.scan_counts;
        self.upcomingScans = res.data.scan_counts.upcoming_scans;
        self.recent_scans = res.data.recent_scans;
        self.scanTrend = res.data.recent_scan_trend.scan_trend;
        self.scanCreditTrend = res.data.recent_scan_trend.scan_credit_trend;
      });
    },
    load: function () {
      const options = {
        method: "GET",
        headers: {
          "content-type": "application/json",
          "org-id": this.$store.state.app.tenantId,
        },
        url: process.env.VUE_APP_TM_API_URL + "dashboard/v1",
      };
      var self = this;
      this.$http(options).then((res) => {
        self.stats = res.data;
        if (self.stats.tasks.total_tasks == 0) {
          this.tasks_completion = [0];
        } else {
          this.tasks_completion = [
            Math.round(
              (self.stats.tasks.completed_tasks /
                self.stats.tasks.total_tasks) *
                100
            ),
          ];
        }
        this.vuln_values = [
          self.stats.vulnerabilities.low_vulns,
          self.stats.vulnerabilities.medium_vulns,
          self.stats.vulnerabilities.high_vulns,
          self.stats.vulnerabilities.critical_vulns,
        ];
        this.main_data_loading = false;
      });
      const options_bp = {
        method: "GET",
        headers: {
          "content-type": "application/json",
          "org-id": this.$store.state.app.tenantId,
        },
        url:
          process.env.VUE_APP_TM_API_URL + "dashboard/v2/breached-email-chart",
      };
      var self = this;
      this.$http(options_bp).then((res) => {
        self.breached_data = res.data;
        this.breached_values = [
          self.breached_data.addressed_email_count,
          self.breached_data.total_email_count,
          // self.breached_data.breached_email_count,
        ];
      });

      // cvss based vuln
      // const options_cvss = {
      //   method: "GET",
      //   headers: { "content-type": "application/json" },
      //   url:
      //     process.env.VUE_APP_CISO_API_URL +
      //     "dashboard/v2/cvss-score-based-vulns?org_id=" +
      //     this.org_id,
      // };
      // var self = this;
      // this.$http(options_cvss).then((res) => {
      //   self.vuln_cvss = res.data;
      //   this.cvss_values = [
      //     self.vuln_cvss.low_cvss_score,
      //     self.vuln_cvss.medium_cvss_score,
      //     self.vuln_cvss.high_cvss_score,
      //     self.vuln_cvss.critical_cvss_score,
      //   ];
      // });

      // general Counts
      const options_g = {
        method: "GET",
        headers: {
          "content-type": "application/json",
          "org-id": this.$store.state.app.tenantId,
        },
        url: process.env.VUE_APP_TM_API_URL + "dashboard/v2/general-counts",
      };
      var self = this;
      this.$http(options_g).then((res) => {
        self.generalCount = res.data;
      });

      // asset new api
      // const options_ac = {
      //   method: "GET",
      //   headers: { "content-type": "application/json" },
      //   url:
      //     process.env.VUE_APP_CISO_API_URL +
      //     "dashboard/v2/asset-dashboard?org_id=" +
      //     this.org_id,
      // };
      // var self = this;
      // this.$http(options_ac).then((res) => {
      //   self.assetCount = res.data;
      // });

      // vuln counts
      // const options_vuln = {
      //   method: "GET",
      //   headers: { "content-type": "application/json" },
      //   url:
      //     process.env.VUE_APP_CISO_API_URL +
      //     "dashboard/v2/vulnerability-counts?org_id=" +
      //     this.org_id,
      // };
      // var self = this;
      // this.$http(options_vuln).then((res) => {
      //   self.vulnCount = res.data;
      // });

      // asset counts
      // const options_ac = {
      //   method: "GET",
      //   headers: { "content-type": "application/json" },
      //   url:
      //     process.env.VUE_APP_CISO_API_URL +
      //     "dashboard/v2/asset-counts?org_id=" +
      //     this.org_id,
      // };
      // var self = this;
      // this.$http(options_ac).then((res) => {
      //   self.assetCount = res.data;
      // });

      // scan counts

      // const options_sc = {
      //   method: "GET",
      //   headers: { "content-type": "application/json" },
      //   url:
      //     process.env.VUE_APP_CISO_API_URL +
      //     "dashboard/v2/scan-counts?org_id=" +
      //     this.org_id,
      // };
      // var self = this;
      // this.$http(options_sc).then((res) => {
      //   self.scanCount = res.data;
      //   self.upcomingScans = res.data.upcoming_scans;
      // });
      // 3 months scan trend

      // const options_st = {
      //   method: "GET",
      //   headers: { "content-type": "application/json" },
      //   url:
      //     process.env.VUE_APP_CISO_API_URL +
      //     "dashboard/v2/recent-scan-trend?org_id=" +
      //     this.org_id,
      // };
      // var self = this;
      // this.$http(options_st).then((res) => {
      //   self.scanTrend = res.data.scan_trend;
      //   self.scanCreditTrend = res.data.scan_credit_trend;
      // });
      // DATA FOR GRAPH
      const options_o = {
        method: "GET",
        headers: {
          "content-type": "application/json",
          "org-id": this.$store.state.app.tenantId,
        },
        url: process.env.VUE_APP_TM_API_URL + "dashboard/v2",
      };
      var self = this;
      this.$http(options_o).then((res) => {
        self.overallrisk = res.data;
        self.internalrisk = res.data.internal_asset_data;
        self.internetrisk = res.data.internet_facing_data;

        // if (self.stats.tasks.total_tasks == 0) {
        //   this.tasks_completion = [0];
        // } else {
        //   this.tasks_completion = [
        //     Math.round(
        //       (self.stats.tasks.completed_tasks /
        //         self.stats.tasks.total_tasks) *
        //         100
        //     ),
        //   ];
        // }
        // this.overall_vuln_values = [
        //   self.overallrisk.vulnerabilities.low_vulns,
        //   self.overallrisk.vulnerabilities.medium_vulns,
        //   self.overallrisk.vulnerabilities.high_vulns,
        //   self.overallrisk.vulnerabilities.critical_vulns,
        // ];
        this.main_data_loading = false;
      });

      // LOAD VULN GRAPH
      // const options_v = {
      //   method: "GET",
      //   headers: { "content-type": "application/json" },
      //   url:
      //     process.env.VUE_APP_CISO_API_URL +
      //     "dashboard/v2/vulnerabilities?org_id=" +
      //     this.org_id,
      // };
      // var self = this;
      // this.$http(options_v).then((res) => {
      //   self.overallVuln = res.data.overall_vuln_data.vulnerabilities;
      //   self.vulnData = res.data;

      // if (self.stats.tasks.total_tasks == 0) {
      //   this.tasks_completion = [0];
      // } else {
      //   this.tasks_completion = [
      //     Math.round(
      //       (self.stats.tasks.completed_tasks /
      //         self.stats.tasks.total_tasks) *
      //         100
      //     ),
      //   ];
      // }
      // this.overall_vuln_values = [
      //   self.overallrisk.vulnerabilities.low_vulns,
      //   self.overallrisk.vulnerabilities.medium_vulns,
      //   self.overallrisk.vulnerabilities.high_vulns,
      //   self.overallrisk.vulnerabilities.critical_vulns,
      // ];
      // });
      //  LOAD GROUP DATA
      // var self = this;
      // this.$http({
      //   method: "GET",
      //   headers: { "content-type": "application/json" },
      //   url:
      //     process.env.VUE_APP_CISO_API_URL +
      //     "dashboard/v1/group_data?org_id=" +
      //     this.org_id,
      // }).then((res) => {
      //   this.group_filter = [];
      //   for (const key in res.data) {
      //     if (res.data[key].groupname != null) {
      //       let g = {
      //         value: key,
      //         text: res.data[key].groupname,
      //       };
      //       self.group_filter.push(g);
      //     }
      //   }
      //   self.group_data = res.data;
      //   this.group_data_loading = false;
      // });

      // Load Groups

      // const a_options = {
      //   method: "GET",
      //   headers: { "content-type": "application/json" },
      //   url: process.env.VUE_APP_CISO_API_URL + "asset/group?org_id=" + this.org_id,
      // };
      // var self = this;
      // this.$http(a_options).then((res) => {
      //   res.data.results.map(function (value, key) {
      //     let a = {
      //       value: res.data.results[key].group_id,
      //       text: res.data.results[key].group_name,
      //     };
      //     // console.log(res.data[key].profile.organization)
      //     self.group_filter_options.push(a);
      //   });
      // });
    },
    onOrgChange: function () {
      this.users = [];
      this.assetGroups = [];
      const o_options = {
        method: "GET",
        headers: {
          "content-type": "application/json",
          "org-id": this.$store.state.app.tenantId,
        },
        url:
          process.env.VUE_APP_CISO_API_URL +
          "user/users/get-all-users?q_org_id=" +
          this.org_id,
      };
      var self = this;
      this.$http(o_options).then((res) => {
        // console.log(res.data)
        res.data.map(function (value, key) {
          let user = {
            value: res.data[key].id,
            text: res.data[key].username,
          };
          // console.log(res.data[key].profile.organization)
          self.users.push(user);
        });
      });
      // this.load();
    },

    // updateData() {
    //   debugger
    //   this.customersPie = {
    //       series: [700, 500, 200]
    //   };
    // }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";
@import "vue-select/src/scss/vue-select.scss";
[dir] .vs--searchable .vs__dropdown-toggle {
  padding-bottom: 6px !important;
  padding-top: 4px !important;
  background-color: white;
}
[dir="ltr"] .vs__search,
[dir="ltr"] .vs__search:focus {
  color: #6e6b7b;
}

.dark-layout .vs__search,
[dir="ltr"] .vs__search:focus {
  color: #b4b7bd;
}
.dark-layout .vs--searchable .vs__dropdown-toggle {
  padding-bottom: 6px;
  padding-top: 4px;
  background-color: #283046;
}
</style>
